import React, { useState } from "react";
import "./App.css";

const App: React.FC = () => {
  const addNetwork = async () => {
    try {
      const provider = (window as any).ethereum;
      if (!provider) {
        alert("MetaMask is not installed!");
        return;
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x301824", // Hexadecimal of 3151908
            chainName: "t1 Devnet v0.1",
            rpcUrls: ["https://rpc.devnet.t1protocol.com"],
            blockExplorerUrls: ["https://explorer.devnet.t1protocol.com"],
            nativeCurrency: {
              name: "Ethereum",
              symbol: "ETH",
              decimals: 18,
            },
          },
        ],
      });
      alert("t1 Devnet v0.1 added to MetaMask!");
    } catch (error) {
      console.error("Error adding network:", error);
    }
  };

  const [isCopiedRPC, setIsCopiedRPC] = useState(false);
  const [isCopiedWSRPC, setIsCopiedWSRPC] = useState(false);
  const [isCopiedExplorer, setIsCopiedExplorer] = useState(false);
  const [isCopiedChainID, setIsCopiedChainID] = useState(false);

  const copyTextToClipboard = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>𝚝𝟷 Devnet Portal</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
	  <p>Welcome to 𝚝𝟷 devnet portal. Below, we:</p>
          <ul>
	    <li>guide you through a demo that allows users to bridge funds from L1, use T-DEX on t1 and withdraw funds to L1 in two L1 slots and</li>
	    <li>share resources with developer who want to deploy applications on 𝚝𝟷 devnet.</li>
	  </ul>
	  <h2>Demo</h2>
	  <p>Imagine you have funds on your favorite rollup. You execute a swap and you want to receive your funds back on Ethereum. Today, you’ll have to run multiple transactions. You will either use risky third party bridges and wait for minutes, or leverage canonical rollup bridges and wait for hours or days while the rollup state change is verified on Ethereum. This friction limits composability in the broader Ethereum ecosystem and fragments liquidity.</p>
          <p>Now, consider a seamless experience where a transaction executed on a rollup can be verified on Ethereum’s L1 in the very next block. No delays for Alice to withdraw her funds back to L1 and no fragmented liquidity. The ecosystem feels unified, applications operate in harmony, and users enjoy near-instant settlement to Ethereum. This is what we’re building with 𝚝𝟷.</p>
	  <p>𝚝𝟷 devnet is a custom RETH implementation that runs inside Intel TDX. 𝚝𝟷 devnet currently has 1 second block time. We batch multiple 𝚝𝟷 state roots and send these updated state roots which represents the state of 𝚝𝟷 to Ethereum.</p>
          <p>In this demo, you will first bridge funds from Ethereum Sepolia network to 𝚝𝟷. You will then trade on T-DEX, the frequent batch auction DEX that’s built on 𝚝𝟷. And finally you’ll be able to withdraw your tokens and claim them on Ethereum Sepolia EOA. This round trip can take as little as two L1 blocks (slots).</p>
	<p></p>
	<p>Now let’s go through this step-by-step!</p>
        </section>
        <section>
          <h2>Initial setup</h2>
	  <ul>
            <li><button onClick={() => addNetwork()}>Add 𝚝𝟷 devnet to MetaMask</button></li>
	    <li>Get ETH from Ethereum Sepolia Testnet (Ethereum) Faucet</li>
	  </ul>
        </section>

        <section>
          <h3>Bridging assets to t1</h3>
          <ul>
	    <li>Go to the <a href="https://bridge.devnet.t1protocol.com" target="_blank" rel="noreferrer">t1 bridge</a> to bridge funds from Ethereum to t1.</li>
	    <li>Bridge funds from Ethereum to t1.</li>
	    <li>You can now see on the <a href="https://explorer.devnet.t1protocol.com" target="_blank" rel="noreferrer">t1 devnet explorer</a> that your funds are available in your t1 wallet.</li>
          </ul>
        </section>

        <section>
          <h3>Swapping assets on T-DEX</h3>
	  <ul>
            <li>Go to <a href="https://d33ynhssf8imk5.cloudfront.net" target="_blank" rel="noreferrer">T-DEX</a> and make sure your RPC endpoint points to t1.</li>
	    <li>You can now swap between ETH - USDT using limit or market orders on T-DEX</li>
	    <li>You can see these trades on the <a href="https://explorer.devnet.t1protocol.com" target="_blank" rel="noreferrer">t1 devnet explorer</a>.</li>
	  </ul>
        </section>

        <section>
          <h3>Withdrawing assets back to Ethereum</h3>
	  <ul>
	    <li>Go to the <a href="https://bridge.devnet.t1protocol.com" target="_blank" rel="noreferrer">t1 bridge</a>.</li>
	    <li>Withdraw funds from t1 to Ethereum:
	      <p><i>This step will submit the t1 state—the state root, transaction root, withdrawal root, and the TEE proof—to the t1 canonical bridge contract on Ethereum to allow withdrawal of your funds back to L1.</i></p>
	    </li>
	    <li>You can now claim your assets back on Ethereum.</li>
	  </ul>
        </section>

        <section>
          <h2>Develop on 𝚝𝟷!</h2>
          <p>All you need to develop your own dApp on 𝚝𝟷 devnet:</p>

          <div className="code-block">
            <code>RPC: https://rpc.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("https://rpc.devnet.t1protocol.com", setIsCopiedRPC)
              }
            >
              {isCopiedRPC ? "Done" : "Copy"}
            </button>
          </div>

          <div className="code-block">
            <code>WS-RPC: wss://ws.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("wss://ws.devnet.t1protocol.com", setIsCopiedWSRPC)
              }
            >
              {isCopiedWSRPC ? "Done" : "Copy"}
            </button>
          </div>

          <div className="code-block">
            <code>Explorer: https://explorer.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard(
                  "https://explorer.devnet.t1protocol.com",
                  setIsCopiedExplorer
                )
              }
            >
              {isCopiedExplorer ? "Done" : "Copy"}
            </button>
          </div>

	  <div className="code-block">
            <code>Chain ID: 3151908</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("3151908", setIsCopiedChainID)
              }
            >
              {isCopiedChainID ? "Done" : "Copy"}
            </button>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 𝚝𝟷 Devnet Portal</p>
      </footer>
    </div>
  );
};

export default App;

